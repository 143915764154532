import React, { useEffect } from 'react'
import HamburgerMenuStyle from './HamburgerMenuStyle.module.scss'
import './HamburgerMenuStyle.module.scss'
import closeNavbar from './HamburgerMenuBehavior'

function HamburgerMenu() {
  useEffect (() => {
    closeNavbar();
  });

  return (
    <div className={HamburgerMenuStyle.MenuOverlap} id='MenuOverlap'>
      <button className={HamburgerMenuStyle.HamburgerMenu} id='HamburgerMenu'>
        <div className={HamburgerMenuStyle.line}></div>
        <div className={HamburgerMenuStyle.line}></div>
        <div className={HamburgerMenuStyle.line}></div>
      </button>
    </div>
  )
}

export default HamburgerMenu