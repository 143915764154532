import React, { useEffect } from 'react'
import StandardNavlink from '../../Atoms/Navlink/Standard/StandardNavlink'
import NavbarStyle from './NavbarStyle.module.scss'
import HamburgerMenu from '../../Atoms/Buttons/HamburgerMenu/HamburgerMenu'
import NavlinkContainer from '../../Molecules/SpecialComponents/NavlinkContainer/NavlinkContainer'
import PreventDragSelect from './NavbarPreventDrag'

function Navbar({ initialState }) {
  useEffect (() => {
    PreventDragSelect();
  });

  let NavbarClasses = `${NavbarStyle.Navbar}`;

  if (initialState.includes("hidden")) {
    NavbarClasses = `${NavbarStyle.Navbar} ${NavbarStyle.Hidden}`;
  }


  return (
    <div className={NavbarClasses} id='Navbar'>
      <HamburgerMenu />

      <NavlinkContainer additionalClasses={"about-me"}/>
      <StandardNavlink additionalClasses={"python"}/>
      <StandardNavlink additionalClasses={"web-dev"}/>
      <StandardNavlink additionalClasses={"java"}/>
      <StandardNavlink additionalClasses={"database"}/>
      <StandardNavlink additionalClasses={"cpp"}/>
    </div>
  )
}

export default Navbar