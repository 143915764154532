import React from 'react';
import DarkModeButton from '../../Atoms/Buttons/DarkMode/DarkModeButton';
import LightModeButton from '../../Atoms/Buttons/LightMode/LightModeButton';
import Navbar from '../../Organisms/Navbar/Navbar';
import Hobbys from '../../Organisms/AboutMe/Hobbys/Hobbys';
import '../PageStyles.scss'

const Homepage = () => {
  return (
    <div className="page" id='Homepage'>
      <Navbar  initialState={""}/>
      <Hobbys />
    </div>
  );
};

export default Homepage;