import React from 'react'
import HobbysStyles from './HobbysStyles.module.scss'

function Hobbys() {
  return (
    <div className={HobbysStyles.HobbyOrganism} id='java-org-element'>
        testtext
    </div>
  )
}

export default Hobbys