import React from 'react'
import Navbar from '../../Organisms/Navbar/Navbar'
import '../PageStyles.scss'

function NotFoundPage() {
  return (
    <div className='page' id='NotFoundPage'>
      <Navbar initialState={"hidden"}/>
      <h1>ERROR. Page not found!</h1>
    </div>
  )
}

export default NotFoundPage