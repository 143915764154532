import NavlinkContainerStyles from './NavlinkContainerStyles.module.scss';

const handleOnMouseLeave = () => {
    const navContainers = document.querySelectorAll("#NavlinkContainer");
    const inContainerElements = document.getElementsByClassName(NavlinkContainerStyles.InContainer);

    navContainers.forEach(navContainer => {
        navContainer.addEventListener("mouseleave", () => {
            for (const element of inContainerElements) {
                element.classList.remove(NavlinkContainerStyles.Highlighted)
            }
        });
    });
}

export default  handleOnMouseLeave