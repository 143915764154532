import NavbarStyles from '../../../Organisms/Navbar/NavbarStyle.module.scss'

const closeNavbar = () => {
    const hiddenClass = NavbarStyles.Hidden;
    document.getElementById("HamburgerMenu").onclick = e => {
        const navbar = document.getElementById("Navbar")

        if (!navbar.classList.contains(hiddenClass)) {
            navbar.classList.add(hiddenClass);
        }
        else {
            navbar.classList.remove(hiddenClass);
        }
    }
}

export default closeNavbar;