import NavlinkContainerStyles from '../../../Molecules/SpecialComponents/NavlinkContainer/NavlinkContainerStyles.module.scss';
import NavlinkStyles from '../../Navlink/NavlinkStyles.module.scss'

const followCursor = () => {
  const standardnavelement = NavlinkStyles.Navlink;
  const navcontainerelement = NavlinkContainerStyles.Navlink
  document.getElementById("Navbar").onmousemove = e => {
    for(const navlink of document.getElementsByClassName(standardnavelement)) {
      const rect = navlink.getBoundingClientRect(),
        x = e.clientX - rect.left,
        y = e.clientY - rect.top;

      navlink.style.setProperty("--Xpos", `${x}px`);
      navlink.style.setProperty("--Ypos", `${y}px`);
    }

    for(const navlink of document.getElementsByClassName(navcontainerelement)) {
      const rect = navlink.getBoundingClientRect(),
        x = e.clientX - rect.left,
        y = e.clientY - rect.top;

      navlink.style.setProperty("--Xpos", `${x}px`);
      navlink.style.setProperty("--Ypos", `${y}px`);
    }
  }
}

export default followCursor;