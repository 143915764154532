import { React, useEffect} from 'react'
import StandardNavlink from '../../../Atoms/Navlink/Standard/StandardNavlink'
import NavlinkContainerStyles from './NavlinkContainerStyles.module.scss'
import handleOnMouseLeave from './NavlinkContainerBehavior'


function NavlinkContainer({ additionalClasses }) {
  useEffect(() => {

    handleOnMouseLeave();
  });

  let BeforeElementContent = additionalClasses.charAt(0).toUpperCase() + additionalClasses.slice(1);
  if (BeforeElementContent === "Cpp") {
    BeforeElementContent = "C++";
  } else if (BeforeElementContent === "About-me") {
    BeforeElementContent = "About Me";
  };

  const NavClasses = `${NavlinkContainerStyles.NavlinkContainer} ${NavlinkContainerStyles.Navlink}`;
  const NavcontentClasses = `${NavlinkContainerStyles.NavlinkContent} ${additionalClasses}`;

  return (
    <div className={NavClasses} id='NavlinkContainer'>
      <div className={NavlinkContainerStyles.NavlinkBorder}></div>
      <StandardNavlink additionalClasses={"python"} inContainer={"true"}/>
      <StandardNavlink additionalClasses={"web-dev"} inContainer={"true"}/>
      <StandardNavlink additionalClasses={"java"} inContainer={"true"}/>
      <StandardNavlink additionalClasses={"database"} inContainer={"true"}/>
      <StandardNavlink additionalClasses={"cpp"} inContainer={"true"}/>
      <div className={NavcontentClasses} ></div>
      <div className={NavlinkContainerStyles.Overlay} >
        <div className={NavlinkContainerStyles.OverlayText} > {BeforeElementContent} </div>
      </div>
    </div>
    
  )
}

export default NavlinkContainer