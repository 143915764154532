import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './components/Pages/Homepage/Homepage';
import NotFoundPage from './components/Pages/NotFoundPage/NotFoundPage';

function App() {
  return (
    <Router>   
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route path="/home" element={<Homepage />} />
        <Route path='/*' element={<NotFoundPage />} />
      </Routes>
    </Router> 
  );
};

export default App;
