import NavlinkContainerStyles from "../../../Molecules/SpecialComponents/NavlinkContainer/NavlinkContainerStyles.module.scss";
import GeneralOrgStyles from '../../../Organisms/AboutMe/Hobbys/HobbysStyles.module.scss'

function transitionIntoBg(element) {
    element.style.transition = "all 400ms ease-in-out";
    element.style.opacity = 1;
    const middleX = window.innerWidth / 2;
    const middleY = window.innerHeight / 3 ;
    element.style.transform = `translate(${middleX}px, ${middleY}px) scale(5)`;
    setTimeout(() => {
        element.style.transition = "all 300ms ease-in-out";
        element.style.transform = `translate(${middleX}px, ${middleY}px) scale(30)`;
        element.style.opacity = 0;
    }, 400); 
    setTimeout(() => {
        element.style.display = "none";
    }, 800);
}

function transitionOutOfBg(element, previousTransform) {
    element.style.display = "block";
    element.style.opacity = 0;
    element.style.transition = "all 300ms ease-in-out";
    const middleX = window.innerWidth / 2;
    const middleY = window.innerHeight / 3 ;
    element.style.transform = `translate(${middleX}px, ${middleY}px) scale(5)`;
    setTimeout(() => {
        element.style.transition = "all 500ms ease-in-out";
        element.style.transform = previousTransform;
        element.style.opacity = 1;
    }, 300); 

    element.addEventListener('transitionend', function transitionEndHandler() {
        element.style.transition = ""; 
        element.style.transform = ""; 
        element.style.opacity = ""; 
        
        element.removeEventListener('transitionend', transitionEndHandler);
    });
}

const handleOnClick = ( orgElement ) => {
    const navContainers = document.querySelectorAll("#NavlinkContainer");
    console.log(orgElement)
    let previouselement = "";
    let previousElementTransform = null;
    let highestZIndexElement = null;

    var tempvar = 0;

    navContainers.forEach(navContainer => {
        navContainer.addEventListener("click", (e) => {
            const mouseX = e.clientX;
            const mouseY = e.clientY;

            var elements = [];
            var elementsAtPosition = document.elementsFromPoint(mouseX, mouseY);
            for (var i = 0; i < elementsAtPosition.length; i++) {
                if (elementsAtPosition[i].classList.contains(NavlinkContainerStyles.InContainer)) {
                    elements.push(elementsAtPosition[i]);
                }
            }

            for (const target of elements) {
                if(getComputedStyle(target).zIndex > tempvar) {
                    tempvar = getComputedStyle(target).zIndex;
                    highestZIndexElement = target;
                }
            }

            tempvar = 0;
            if (highestZIndexElement) {
                highestZIndexElement.classList.add(NavlinkContainerStyles.Highlighted)

                if (highestZIndexElement !== previouselement) {
                    transitionIntoBg(highestZIndexElement);

                    if (orgElement !== "") {
                        orgElement.classList.add(GeneralOrgStyles.Visible)
                    }
                }

                if (previouselement === "") {
                    previousElementTransform = getComputedStyle(highestZIndexElement).transform;
                    previouselement = highestZIndexElement;
                } else {
                    if (previouselement === highestZIndexElement) {
                        return;
                    } else {
                        previouselement.classList.remove(NavlinkContainerStyles.Highlighted)
                        previouselement.classList.remove(NavlinkContainerStyles.Hidden)
                        previouselement.classList.add(NavlinkContainerStyles.ReVisibleTransition)
                        
                        transitionOutOfBg(previouselement, previousElementTransform);

                        previouselement = highestZIndexElement;
                        previousElementTransform = getComputedStyle(highestZIndexElement).transform;
                    }
                }           
            }
        });
    });
}


const handleOnClicks = ( orgElement ) => {
    const navContainers = document.querySelectorAll("#NavlinkContainer");
    let previousOrgElement = "";
    let previouselement = "";
    let highestZIndexElement = null;

    var tempvar = 0;

    navContainers.forEach(navContainer => {
        navContainer.addEventListener("click", (e) => {
            const mouseX = e.clientX;
            const mouseY = e.clientY;

            var elements = [];
            var elementsAtPosition = document.elementsFromPoint(mouseX, mouseY);
            for (var i = 0; i < elementsAtPosition.length; i++) {
                if (elementsAtPosition[i].classList.contains(NavlinkContainerStyles.InContainer)) {

                    elements.push(elementsAtPosition[i]);
                }
            }

            for (const target of elements) {
                if(getComputedStyle(target).zIndex > tempvar) {
                    tempvar = getComputedStyle(target).zIndex;
                    highestZIndexElement = target;
                }
            }

            tempvar = 0;
            if (highestZIndexElement) {
            
                highestZIndexElement.classList.add(NavlinkContainerStyles.Highlighted);
            
                orgElement.classList.add(GeneralOrgStyles.Visible);
                

                if (highestZIndexElement.classList.contains(NavlinkContainerStyles.ReVisibleTransition)) {
                    
                    highestZIndexElement.classList.remove(NavlinkContainerStyles.ReVisibleTransition);
                
                    orgElement.classList.remove(GeneralOrgStyles.Visible)
                }
                
                setTimeout(function() {
                    highestZIndexElement.classList.add(NavlinkContainerStyles.Hidden);
                    orgElement.classList.add(GeneralOrgStyles.Hidden);
                }, 1000);

                if (previouselement === "") {
                    previouselement = highestZIndexElement;
                    previousOrgElement = orgElement;
                } else {
                    if (previouselement === highestZIndexElement) {
                        return;
                    } else {
                        previouselement.classList.remove(NavlinkContainerStyles.Highlighted)
                        previouselement.classList.remove(NavlinkContainerStyles.Hidden)
                        previouselement.classList.add(NavlinkContainerStyles.ReVisibleTransition)
                        
                        previousOrgElement.classList.remove(GeneralOrgStyles.Hidden)
                        previousOrgElement.classList.add(GeneralOrgStyles.Visible)
                        
                        setTimeout(function() {
                            previouselement.classList.remove(NavlinkContainerStyles.ReVisibleTransition);

                            previousOrgElement.classList.remove(GeneralOrgStyles.Visible)
                        }, 1000);

                        previouselement = highestZIndexElement;
                        previousOrgElement = orgElement;
                    }
                }

                
            }
        });
    });
}

export default handleOnClick;