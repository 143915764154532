import NavbarStyles from './NavbarStyle.module.scss'

const PreventDragSelect = () => {
    const navbar = document.getElementById('Navbar');

    // Prevent text selection
    navbar.addEventListener('mousedown', function(event) {
        event.preventDefault();
    });

    // Prevent dragging
    navbar.addEventListener('dragstart', function(event) {
        event.preventDefault();
    });
}


export default PreventDragSelect