import React, { useEffect } from 'react'
import NavlinkStyles from '../NavlinkStyles.module.scss'
import '../NavlinkBackgroundStyles.scss'
import handleOnClick from './NavlinkClickBehavior';
import followCursor from './NavlinkOverlayBehavior';
import NavlinkContainerStyles from '../../../Molecules/SpecialComponents/NavlinkContainer/NavlinkContainerStyles.module.scss'


function StandardNavlink({ additionalClasses, inContainer }) {
  useEffect(() => {
    if ( additionalClasses === "c++" ) {
      additionalClasses = "cpp";
    }

    let orgElement = document.getElementById(`${additionalClasses}-org-element`)

    if (!orgElement) {
      orgElement = "";
    } 
   
    handleOnClick( orgElement );
    followCursor();
  });

  let BeforeElementContent = additionalClasses.charAt(0).toUpperCase() + additionalClasses.slice(1);
  if (BeforeElementContent === "Cpp") {
    BeforeElementContent = "C++";
  } else if (BeforeElementContent === "Web-dev") {
    BeforeElementContent = "Web Dev";
  }

  let NavClasses = `.navlink ${NavlinkStyles.StandardNavlink} ${NavlinkStyles.Navlink}`;
  const NavcontentClasses = `${NavlinkStyles.NavlinkContent} ${additionalClasses}`;

  if (inContainer === "true") {
    NavClasses = `.navlink ${NavlinkContainerStyles.InContainer} ${NavlinkStyles.StandardNavlink} ${NavlinkStyles.Navlink}`;
  }

  return (
    <div className={NavClasses} id='StandardNavlink'>
      <div className={NavlinkStyles.NavlinkBorder} ></div>
      <div className={NavcontentClasses}></div>
      <div className={NavlinkStyles.Overlay}>
        <div className={NavlinkStyles.OverlayText}> {BeforeElementContent} </div>
      </div>
    </div>
  )
}

export default StandardNavlink